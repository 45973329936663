import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const title = "Module 3";
export const description = "";
export const image = "/chapter_headers/serverless-elements.png";
export const _frontmatter = {};
const layoutProps = {
  title,
  description,
  image,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "listing-user-pages-on-the-dashboard"
    }}>{`Listing user pages on the dashboard`}</h1>
    <p>{`In this section we list user pages on the dashboard. Login, see the pages you own, edit anything, create new ones.`}</p>
    <p>{`Very friendly :)`}</p>
    <h2 {...{
      "id": "use-static-query-to-load-page-list"
    }}>{`Use static query to load page list`}</h2>
    <Vimeo id="396353247" mdxType="Vimeo" />
    <h2 {...{
      "id": "render-page-list"
    }}>{`Render page list`}</h2>
    <Vimeo id="396353389" mdxType="Vimeo" />
    <h2 {...{
      "id": "static-to-dynamic-page-list"
    }}>{`Static-to-dynamic page list`}</h2>
    <Vimeo id="396963395" mdxType="Vimeo" />
    <h2 {...{
      "id": "refactor-to-a-custom-hook"
    }}>{`Refactor to a custom hook`}</h2>
    <Vimeo id="396962723" mdxType="Vimeo" />
    <h2 {...{
      "id": "an-idea-you-can-try"
    }}>{`An idea you can try`}</h2>
    <p>{`Right now you have to reload the dashboard to see new pages on the list after creating. Would be cool, if that happened dynamically.`}</p>
    <p>{`Try to update the pageList as soon as the user clicks `}<inlineCode parentName="p">{`Create`}</inlineCode></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      